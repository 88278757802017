import "@/styles/globals.css";
import "react-international-phone/style.css";

import * as Sentry from "@sentry/react";

import ApolloClientProvider from "@/utils/apollo-client";
import type { AppProps } from "next/app";
import { ChakraProvider } from "@chakra-ui/react";
import Head from "next/head";
import React from "react";
import { get_token } from "@/utils/cookie-storage";
import { theme } from "@/utils/chakra-theme";
import { useRouter } from "next/router";

Sentry.init({
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

export default function App({ Component, pageProps }: AppProps) {
  const router = useRouter();
  const token = get_token();

  React.useEffect(() => {
    if (!token && router.pathname.startsWith("/account")) {
      router.push("/auth");
    }
  }, [token, router]);

  return (
    <ChakraProvider theme={theme}>
      <ApolloClientProvider>
        <Head>
          <title>Agent | Picknfix</title>
        </Head>
        <Component {...pageProps} />
      </ApolloClientProvider>
    </ChakraProvider>
  );
}
